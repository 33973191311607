<template>
    <span ref="latex" class="katex" v-html="rawHTML"></span>
  </template>
  
  <script>
  import katex from "katex";
  import "katex/dist/katex.css";
  export default {
    name: "latexDisplay",
    props: {
       
        infoStr: {
            type: String,
            default: '',
        },
    },
    watch: {
       

        infoStr: {
            handler(newVal, oldVal) {
                if (newVal != 0){
                this.loadStr(newVal)
                }
            },
            immediate: true
        },

    },
    data() {
      return {
        rawHTML: "",
      };
    },
    mounted() {

        console.log(1122334444,this.$slots)
    //   this.rawHTML = katex.renderToString(this.$slots.default[0].text, {
    //     throwOnError: false,
    //   });
      /*
      // 另一种方法
      katex.render(this.$slots.default[0].text, this.$refs.latex, {
        throwOnError: false,
      });
      */
    },
    methods: {

        loadStr(newVal){
            this.rawHTML = katex.renderToString(newVal, {
                throwOnError: false,
            });
        }

    }
  };
  </script>
  
  <style>
  </style>