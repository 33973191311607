<template>
  <div class="exam-root">
    <!--  -->
    <div class="loadingView" v-if="showloadingView">
      <!-- <img src="../../assets/exam_ready_bg.png" class="loadingImg"> -->

      <div class="loadingBgView">
        <div class="loadingBgViewInfo1">{{ test_event }}</div>

      </div>

      <div class="loadingBgView1">
        <!-- <div class="loadingBgViewInfo">考试信息</div> -->
        <!-- <div class="loadingBgViewInfo1">{{ test_event }}</div> -->


        <div class="loadingBgViewInfo1">
          Important Instructions
        </div>

        <div class="loadingInfoStyle1">
          1. This test consists of 200 questions, with a total duration of 120 minutes.<br>
          2. The test has an initial score of 100 points, this is normal, because the wrong answer will be deducted points.<br>
          3. You can only answer the questions in the order specified. After answering each question, the exam system will real-time scoring and proceed to the next question. Points will be awarded for correct answers and deducted for incorrect ones.<br>
          4. Different types of questions are worth different amounts of points, which are shown in the stem of the question. If you do not complete all the questions within the time limit, the unanswered questions will not be scored.<br>
          5. The exam can only be submitted until 30 minutes after the test begins.

        </div>
      </div>

      <div class="hourStyle">
        {{ hourLab }}
      </div>

      <div class="minuteStyle">
        {{ minuteLab }}
      </div>

      <div class="secondStyle">
        {{ secondLab }}
      </div>

      <div class="dianStyle">:</div>

      <div class="dianStyle1">:</div>

      <div class="loadingInfoStyle">
        To avoid entry congestion, please be patient and wait for a random
        buffer time. <br />
        After the buffer is over, you can start the exam normally. (Waiting time
        is not counted as exam time)
      </div>
    </div>
    <TextChat
      v-if="isConnect"
      :user_id="user_id"
      :group_id="group_id"
      @correctSessionPicSync="onCorrectSessionPicSync"
      @correctQuestionSync="onCorrectQuestionSync"
    />
    <div class="exam-top">
      
      <div class="projectStyle">{{ test_project_name +' - ' + test_event }}</div>
      <div class="projectStyle1">{{student_name}}</div>
     
    </div>
    <div class="exam-content" id="exam-content">
      <div class="exam-empty-width-view" />
      <div style="flex: 1; display: flex; flex-direction: column; overflow: hidden">
        <div class="exam-empty-height-view" />
        <div style="width: 100%; flex: 1; overflow: hidden;">
          <div v-if="canExam && havePicUrl == true" class="exam-content-page">
            <div
              style="width: 100%; flex: 1; overflow-y: scroll"
              id="exam-image"
            >
              <div class="exam-image">
                <PDF ref="pdfView" @pdfCallback="pdfCallback" :parentReloadPic="parentReloadPic" :parentShowBigPic="parentShowBigPic"></PDF>
              </div>
            </div>
            <div class="exam-page-bottom">

              <div class="exam-page-bottom">

                <div class="reloadDiv" @click="showReload">
                  Reload
                </div>

                <div class="reloadDiv" @click="showPreImage">
                  {{ getShowWord("page_pre") }}
                </div>

                <div class="reloadDiv" >
                  {{
                    examImageSize > 0
                      ? currentSelectedImageIndex + "/" + examImageSize
                      : "0/0"
                  }}
                </div>

                <div class="reloadDiv" @click="showNextImage">
                  {{ getShowWord("page_next") }}
                </div>

                <div class="reloadDiv" @click="enlargeClick">
                  Enlarge
                </div>


                <!-- <div class="exam-page-bottom-text" @click="showPreImage">
                  {{ getShowWord("page_pre") }}
                </div>
                <div class="exam-page-bottom-text">
                  {{
                    examImageSize > 0
                      ? currentSelectedImageIndex + "/" + examImageSize
                      : "0/0"
                  }}
                </div>
                <div class="exam-page-bottom-text" @click="showNextImage">
                  {{ getShowWord("page_next") }}
                </div> -->
              </div>
              <!-- <div class="exam-goto-page" @click="beforeShowSelectImageDialog">
                {{ getShowWord("gotoPage") }}
              </div> -->
              
            </div>

          </div>

          <div v-if="canExam && havePicUrl == false" class="exam-content-nourl-page" >
            <span style="font-size: 18px;font-weight: 500;">Instructions:</span> <br>
            After completing a question, click "Confirm" below the question. Upon doing so, the system will immediately update your total score and proceed to the next question. Once you click "Confirm," you will not be able to modify your answer or review previously answered questions.<br><br>

            If you experience any technical issues with the system, you can send a message to the organizing committee by clicking on the "Message" option in the right-hand menu.<br>
            Please note that there may be no staff available to assist you if the exam time falls between 15:00 and 23:00 UTC.<br><br>

            <span style="font-size: 18px;font-weight: 500;">Frequently Asked Questions:</span><br>

            <span style="font-size: 18px;font-weight: 500;">Will an accidental screen switch impact my test score?</span><br>
            Screen switch does not affect scores directly. Abnormal screen switches will be reviewed manually. As long as there is no actual cheating or rule violation, it will not impact your grade.<br><br>

            <span style="font-size: 18px;font-weight: 500;">Can I use the bathroom during the test?</span><br>
            Yes, but please limit your break to 2 minutes.<br><br>

            <span style="font-size: 18px;font-weight: 500;">Can I submit the exam early?</span><br>
            Yes, you can submit your exam 30 minutes after the start of the exam.<br><br>

            <span style="font-size: 18px;font-weight: 500;">What if I close the page by mistake?</span><br>
            If you accidentally close the page, you can re-enter the test from the home page. Your answers are saved in real-time as long as the network is stable. Please verify the status of your answers after re-entry.


          </div>

        </div>
        <div class="exam-empty-height-view" />

      </div>
      <div class="exam-empty-width-view" />
      <div style="flex: 1; display: flex; flex-direction: column">
        <div class="exam-empty-height-view" />
        <div
          style="
            width: 100%;
            height: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
          "
        >
          <div
            class="exam-content-page"
            style="background: white"
          >
            <div style="flex: 1; width: 100%; height: 100%; overflow-y: scroll">
              <div class="exam-question-content;">
                <div style="margin: 20px">
                  <div class="questionTitle">
                    <div
                      v-html="currentSelectedQuestion['question_question']"
                    ></div>
                    <!-- <kats style="width: 50px;" :infoStr="currentSelectedQuestion['question_question_string']"></kats> -->

                  </div>
                  <div v-if="currentSelectedQuestion.question_type == 'input'">
                    <el-input
                      class="inputAnswerStyle"
                      type="textarea"
                      :rows="5"
                      placeholder="Please input answers here"
                      v-model="currentSelectedQuestion.inputAnswer"
                      @change="
                        saveAnswer(
                          currentSelectedQuestion.inputAnswer,
                          currentSelectedQuestion
                        )
                      "
                    >
                    </el-input>

                    <div style="display: flex">
                      <a
                        v-if="
                          currentSelectedQuestion.answer_img_url != '' &&
                          currentSelectedQuestion.answer_img_url != undefined
                        "
                        style="margin-top: 20px; margin-left: 20px"
                        class="attach"
                        target="_blank"
                        :href="currentSelectedQuestion.answer_img_url"
                        slot="reference"
                        >Show Pic
                      </a>
                      <div
                        style="
                          display: flex;
                          margin-top: 20px;
                          margin-left: 20px;
                        "
                      >
                        <el-upload
                          class="upload-demo mt-2 attach"
                          ref="upload"
                          action="#"
                          :limit="1"
                          :before-upload="beforeAttachUpload"
                        >
                          <el-button slot="trigger" size="small" type="primary"
                            >Select File
                          </el-button>
                        </el-upload>
                        <el-button
                          size="small"
                          type="danger"
                          v-if="
                            currentSelectedQuestion.answer_img_url != '' &&
                            currentSelectedQuestion.answer_img_url != undefined
                          "
                          style="margin-left: 20px"
                          @click="clickRemoveQuestionUrlAnswer"
                          >Remove</el-button
                        >
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div
                      class="option"
                      v-if="
                        currentSelectedQuestion.question_optionA &&
                        currentSelectedQuestion.question_optionA != ''
                      "
                    >
                      <el-radio
                        @change="saveAnswer('A', currentSelectedQuestion)"
                        :value="currentSelectedQuestion.choice"
                        label="A"
                      >
                        <span class="optionTitle">A</span>
                      </el-radio>
                      <div
                        class="div_p"
                        v-html="currentSelectedQuestion.question_optionA"
                      ></div>
                      <!-- <le-editor  v-model="testAss" :indent-unit="4" :fullscreen="false" hljs-css="agate" :image-uploader="imageUploader" @save="ssssave"></le-editor> -->

                      <!-- <p>Consider the function \(f(x) = \frac[x^2 + 4x + 4}{x^2 - 4}\). What is the domain of ¡(f(x)\)?</p> -->
                      <!-- <kats class="div_p" :infoStr="currentSelectedQuestion['question_optionA_string']"></kats> -->

                    </div>
                    <div
                      class="option"
                      v-if="
                        currentSelectedQuestion.question_optionB &&
                        currentSelectedQuestion.question_optionB != ''
                      "
                    >
                      <el-radio
                        @change="saveAnswer('B', currentSelectedQuestion)"
                        :value="currentSelectedQuestion.choice"
                        label="B"
                      >
                        <span class="optionTitle">B</span>
                      </el-radio>
                      <div
                        class="div_p"
                        v-html="currentSelectedQuestion.question_optionB"
                      ></div>
                      <!-- <kats class="div_p" :infoStr="currentSelectedQuestion['question_optionB_string']"></kats> -->

                    </div>
                    <div
                      class="option"
                      v-if="
                        currentSelectedQuestion.question_optionC &&
                        currentSelectedQuestion.question_optionC != ''
                      "
                    >
                      <el-radio
                        @change="saveAnswer('C', currentSelectedQuestion)"
                        :value="currentSelectedQuestion.choice"
                        label="C"
                      >
                        <span class="optionTitle">C</span>
                      </el-radio>
                      <div
                        class="div_p"
                        v-html="currentSelectedQuestion.question_optionC"
                      ></div>

                      <!-- <kats class="div_p" :infoStr="currentSelectedQuestion['question_optionC_string']"></kats> -->

                    </div>
                    <div
                      class="option"
                      v-if="
                        currentSelectedQuestion.question_optionD &&
                        currentSelectedQuestion.question_optionD != ''
                      "
                    >
                      <el-radio
                        @change="saveAnswer('D', currentSelectedQuestion)"
                        :value="currentSelectedQuestion.choice"
                        label="D"
                      >
                        <span class="optionTitle">D</span>
                      </el-radio>
                      <div
                        class="div_p"
                        v-html="currentSelectedQuestion.question_optionD"
                      ></div>
                      <!-- <kats class="div_p" :infoStr="currentSelectedQuestion['question_optionD_string']"></kats> -->

                    </div>
                    <div
                      class="option"
                      v-if="
                        currentSelectedQuestion.question_optionE &&
                        currentSelectedQuestion.question_optionE != ''
                      "
                    >
                      <el-radio
                        @change="saveAnswer('E', currentSelectedQuestion)"
                        :value="currentSelectedQuestion.choice"
                        label="E"
                      >
                        <span class="optionTitle">E</span>
                      </el-radio>
                      <div
                        class="div_p"
                        v-html="currentSelectedQuestion.question_optionE"
                      ></div>
                      <!-- <kats class="div_p" :infoStr="currentSelectedQuestion['question_optionE_string']"></kats> -->

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="exam-page-bottom">
              <div class="exam-page-bottom">

                <div class="reloadDiv" @click="loadExam">
                  Reload
                </div>

                <div class="reloadDiv" >
                  {{
                    examQuestionSize > 0
                      ? currentSelectedQuestionIndex +
                        1 +
                        "/" +
                        examQuestionSize
                      : "0/0"
                  }}
                </div>

                <div class="reloadDiv" @click="showNextQuestion">
                  Confirm
                </div>
                
                <!-- <div class="exam-page-bottom-text" @click="showPreQuestion">
                  {{ getShowWord("question_pre") }}
                </div>
                <div class="exam-page-bottom-text">
                  {{
                    examQuestionSize > 0
                      ? currentSelectedQuestionIndex +
                        1 +
                        "/" +
                        examQuestionSize
                      : "0/0"
                  }}
                </div>
                <div class="exam-page-bottom-text" @click="showNextQuestion">
                  {{ getShowWord("question_next") }}
                </div> -->


              </div>

              <!-- <div class="exam-menu" @click="showMenu">
                {{ getShowWord("menu") }}
              </div> -->
              <!-- <div
                class="exam-menu"
                style="width: 6vw; background-color: #00d523"
                @click="showMessage"
              >
                <div class="redTip" v-if="showRedTip"></div>
                {{ getShowWord("question") }}
              </div>
              <div class="exam-menu" @click="showQADrawer = true">FAQ</div> -->
            </div>
          </div>
        </div>

        <div class="exam-empty-height-view" />

      </div>
      <div class="exam-empty-width-view" />


      <div style="width: 150px;height: 100%;background-color: white; align-items: center;position: relative;">

        <div class="exam-empty-height-view" />

        <div style="margin:1px;background-color: #f2f2f2;">


          <Invigilate
            v-if="!DEBUG_DEV && signStatus"
            v-model="status"
            :template_id="template_id"
            @invigilateCallback="invigilateCallback"
            @visibleChange="refreshVisible"
            class="invigilateStyle"
          ></Invigilate>

          <div @click="showBrowerTip" class="changeBrowserStyle">
            Browser : {{ browerStr }}
            
          </div>

          <div @click="showExchangeTip" class="changeCountStyle">
            {{ getShowWord("exchange_screen_counts") }}: {{ screenChangeCount }}
          </div>


          <div class="rightMiddleDiv">

            <div class="exam-count-down-lable">Remaining time:</div>
            <div class="exam-count-down">
              {{ countDownTimes   }}
            </div>

            <div style="width: 100%;height: 2px;margin-top: 10px;background-color: white;"></div>


            <div class="exam-count-down-lable">Progress:</div>
            <div class="exam-progess-down">


              <span style="color: red;"> {{currentSelectedQuestionIndex + 1}}</span>  {{ '/ '+ examQuestionSize}}
            </div>

            <div style="width: 100%;height: 2px;margin-top: 10px;background-color: white;"></div>

            <div class="exam-count-down-lable">Total Score:</div>
            <div class="exam-progess-down" style="color: red;margin-bottom: 10px;">
              {{ student_score   }}
            </div>


            <div
              class="exam-message"
              @click="showMessage"
            >
              <div class="redTip" v-if="showRedTip"></div>
              {{ getShowWord("question") }}
            </div>
            <div class="exam-message" @click="showQADrawer = true">FAQ</div>

            <div class="exam-submit" @click="doSubmitAnswer">
              {{ getShowWord("submit_exam") }}
            </div>

            <div>
              <img
                src="../../assets/jiangbei.png"
                style="width: 100%; height: auto"
              />
            </div>

          </div>



          <!-- <div class="bottomDiv">
            

            
          </div> -->



        </div>

      </div>



    </div>

    <!-- 老的提交弹窗 -->
    <!-- <el-dialog
      :visible.sync="showSubmitAnswerDialog"
      :modal="true"
      :show-close="false"
      :modal-append-to-body="true"
      custom-class="showSubmitAnswerDialogStyle"
    >
      <div class="submitAnswer-root">
        <div style="position: absolute; right: 1vw; top: 1vw">
          <img
            src="../../assets/close_1.png"
            style="cursor: pointer; width: 43px; height: auto"
            @click="showSubmitAnswerDialog = false"
          />
        </div>
        <div
          style="
            width: 100%;
            justify-content: center;
            display: flex;
            flex-direction: column;
            position: absolute;
            bottom: 5vh;
          "
        >
          <div style="color: white; font-size: 20px; font-weight: 400;word-break: normal;">
            {{ getShowWord("want_to_submit_answer") }}
          </div>
          <div
            style="
              margin-top: 6vh;
              display: flex;
              justify-content: center;
              flex-direction: row;
              height: 5vh;
              line-height: 5vh;
            "
          >
            <div
              @click="showSubmitAnswerDialog = false"
              style="
                font-weight: 600;
                font-size: 1.67vw;
                cursor: pointer;
                color: #0e409f;
                width: 9vw;
                background-color: #b1ccff;
                border-radius: 31px;
                text-align: center;
              "
            >
              {{ getShowWord("btn_cancel") }}
            </div>
            <div
              @click="uploadAnswers"
              style="
                font-weight: 600;
                font-size: 1.67vw;
                cursor: pointer;
                margin-left: 40px;
                color: #0e409f;
                width: 9vw;
                background-color: white;
                border-radius: 31px;
                text-align: center;
              "
            >
              {{ getShowWord("btn_confirm") }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog> -->


    <el-dialog
      title="Submit Entire Exam"
      :visible.sync="showSubmitAnswerDialog"
      width="30%"
      >
      <span style="word-break: normal;">{{ getShowWord("want_to_submit_answer") }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showSubmitAnswerDialog = false">Cancel</el-button>
        <el-button type="primary" @click="uploadAnswers">Confirm</el-button>
      </span>
    </el-dialog>

    <el-drawer
      :modal="true"
      :show-close="false"
      :modal-append-to-body="true"
      :visible.sync="showDrawer"
      direction="rtl"
      :withHeader="false"
      size="60%"
      custom-class="showMenuDialogStyle"
    >
      <div class="menu-content">
        <div style="margin-top: 100px">
          <img
            src="../../assets/drawer-left.png"
            style="cursor: pointer; width: 28px; height: auto"
            @click="showDrawer = false"
          />
        </div>
        <div class="drawer-question-list" style="margin-left: -2px">
          <div style="padding-top: 30px; padding-bottom: 15px">
            <p
              style="
                letter-spacing: 3px;
                color: white;
                font-size: 20px;
                font-weight: bold;
                font-family: Montserrat-SemiBold;
              "
            >
              {{ drawerAnswerInfo }}
            </p>
          </div>
          <div style="width: 100%; height: 100%; overflow-y: scroll">
            <el-tabs v-model="activeName" stretch="true">
              <el-tab-pane :label="getShowWord('all_questions')" name="first">
                <div>
                  <div
                    v-for="(item, index) in drawerQuestionList"
                    :key="index"
                    class="drawer-question-item-root"
                    @click="doSelectQuestion(index)"
                  >
                    <div
                      :class="{
                        'drawer-question-item': true,
                        selected: item.choice !== '',
                      }"
                    >
                      <div style="flex: 1; display: flex; flex-direction: row">
                        <div
                          v-html="item.question_question"
                          style="
                            margin-left: 10px;
                            text-align: left;
                            font-size: 14px;
                          "
                        ></div>
                      </div>
                      <div class="drawer-answered" v-if="item.choice !== ''">
                        {{ getShowWord("answered") }}
                      </div>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane :label="getShowWord('not_answer')" name="second">
                <div>
                  <div
                    v-for="(item, index) in drawerNoChoiceQuestionList"
                    :key="index"
                    class="drawer-question-item-root"
                    @click="doSelectQuestion(item['parentIndex'])"
                  >
                    <div :class="{ 'drawer-question-item': true }">
                      <div style="flex: 1; display: flex; flex-direction: row">
                        <div
                          v-html="item.question_question"
                          style="margin-left: 10px; text-align: left"
                        ></div>
                      </div>
                      <!--                      <div class="drawer-answered" v-if="item.choice !==''">-->
                      <!--                        已做答-->
                      <!--                      </div>-->
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-dialog :visible.sync="showBigPicDialog" width="100%">
      <img :src="currentSelectedPicPath" />
    </el-dialog>
    <el-dialog
      :visible.sync="showAnswerResultDialog"
      width="100%"
      :modal="true"
      :show-close="false"
      :modal-append-to-body="true"
      custom-class="showAnswerResultDialogStyle"
    >
      <div class="answer-result-root">
        <div class="awardTitle">Congratulations</div>
        <div class="scoreLab2">{{ "/   " + answerResultDta.full_score }}</div>
        <div class="scoreLab1">{{ answerResultDta.total_score }}</div>
        <div class="awardTitle1">{{ answerResultDta.application_event }}</div>
        <div class="awardLine"></div>
        <div class="awardSubTitle"></div>
        <div class="box">
          <div
            v-for="(item, index) in answerResultDta.achievements"
            :key="index"
            class="child"
          >
            <img
              class="infoImg"
              :src="require('../../assets/selectInfoImage.png')"
            />
            <div class="infoStyle">
              {{ "&nbsp;&nbsp;" + item + "&nbsp;&nbsp;" }}
            </div>
          </div>
        </div>
        <div @click="closeView" class="returnStyle">Return Home</div>
      </div>
    </el-dialog>

    <el-drawer
      title="Messages"
      :visible.sync="messageDrawer"
      direction="ltr"
      size="500px"
      :before-close="handleClose"
    >
      <!-- <span>消息列表</span> -->

      <div class="messageList">
        <div class="inputStyle">
          <el-select type="text" class="inputSelect" v-model="messageType">
            <el-option
              value="About Academic"
              label="About Academic"
            ></el-option>
            <el-option value="About Process" label="About Process"></el-option>
            <el-option
              value="About System Operation"
              label="About System Operation"
            ></el-option>
            <el-option value="About Score" label="About Score"></el-option>
            <el-option
              value="About Award and Certificate"
              label="About Award and Certificate"
            ></el-option>
            <el-option value="Others" label="Others"></el-option>
          </el-select>

          <el-input v-model="messageStr" class="inputStyle1"></el-input>
          <el-button class="sendStyle" @click="sendMessage">Send</el-button>
        </div>

        <div style="margin-top: 20px; height: 20px"></div>

        <div
          v-for="(x, index) in messageList"
          :key="index"
          class="messageDetail"
        >
          <div style="color: #001d9f; font-size: 15px">
            {{ x.type }}
          </div>
          <div style="color: #00359f; font-size: 13px; margin-top: 10px">
            {{ x.message_content }}
          </div>

          <div
            v-if="x.reply_content != ''"
            style="color: #00359f; font-size: 13px; margin-top: 10px"
          >
            {{ "Reply：" + x.reply_content }}
          </div>

          <div
            style="background-color: #00359f; height: 1px; margin-top: 10px"
          ></div>
        </div>
      </div>
    </el-drawer>
    <el-dialog :visible.sync="showGotoPageDialog" title="Tips" width="30%">
      <div>
        {{ getShowWord("gotoPage") }}
        <el-input-number
          v-model="gotoImageIndex"
          :min="1"
          :max="examImageSize"
          @change="handleGotoImagChanged"
        ></el-input-number>
        / {{ examImageSize }}
      </div>
      <div style="margin-top: 25px">
        <el-button size="small" type="primary" @click="gotoSelectedImage">
          {{ getShowWord("btn_confirm") }}</el-button
        >
        <el-button
          size="small"
          style="margin-left: 20px"
          @click="showGotoPageDialog = false"
        >
          {{ getShowWord("btn_cancel") }}</el-button
        >
      </div>
    </el-dialog>
    <el-drawer
      title="FAQ"
      :visible.sync="showQADrawer"
      direction="rtl"
      size="40%"
    >
      <div style="text-align: left; margin: 15px">
        <QA />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {
  getExerciseTemplateByStudent,
  getMyMessages,
  getTestVisibleChangeCount,
  sendMessage,
  submitAnswer,
  checkIsUploadTest,
  uploadTestHeartBeat,
  studentSignWithTemplate,
  uploadAnswersV3,
  getGroupId,
  getQiniuToken,
  studentUpdateTemplateWithIndex
} from "../../api/index";
import "../../assets/common/font.css";
import {
  getAppId,
  getProjectCode,
  getUserId,
  getUserIP,
  getTestEvent,
  getProjectName,
  getAccountName,
  getUserName
} from "../../utils/store";
import Invigilate from "./Invigilate";
import QA from "./QA";
import scroll from "../../utils/scroll";
import TextChat from "../CommonChat/txt.vue";
import PDF from "../pdf/PDF.vue";
import { Loading } from "element-ui";
import * as qiniu from "qiniu-js";
import kats from "../../assets/common/kats.vue"



export default {
  name: "StudentExam",
  components: {
    Invigilate,
    QA,
    TextChat,
    PDF,
    kats
  },
  watch: {
    status(newStatus, oldStatus) {
      if (this.invigilateStatus == "true") {
        this.canExam = true;
        this.fetchData(1);
      } else {
        if (!newStatus) {
          //禁止做题目了
          this.canExam = false;
          console.log("---sorry--");
          this.clearCountDown();
          //停止一切
        } else {
          console.log("---yes --");
          this.canExam = true;
          this.fetchData(1);
        }
      }
    },
  },
  data() {
    return {
      // imageUploader: {
      //               custom: false,
      //               fileType: 'file',
      //               fileNameType: '',
      //               imagePrefix: 'http://106.54.92.121', // 图片前缀地址
      //               type: 'server',
      //               url: 'http://106.54.92.121:82/upload'
      //           },
      testAss:"$\\frac{1}{2}$ good udea",
      questionAnswers: {},
      showQADrawer: false,
      gotoImageIndex: 1,
      showGotoPageDialog: false,
      activeName: "first",
      canExam: true,
      answerResultDta: {},
      showAnswerResultDialog: false,
      showDrawer: false,
      messageDrawer: false,
      showSubmitAnswerDialog: false,
      user_id: "",
      template_id: "",
      test_subject: "",
      countDownThread: undefined,
      sections: [],
      countDownTimes: "00:00:00",
      test_event: getTestEvent(),
      test_project_name: getProjectName(),
      student_name:getUserName()+' | '+getAccountName(),
      test_type:'',
      remainStamp: 0,
      status: false,
      exam_images: [],
      currentSelectedImageIndex: 1,
      examImageSize: 0,
      signStatus: false,
      currentSelectedQuestionIndex: 0,
      questionList: [],
      drawerQuestionList: [],
      drawerChoiceQuestionList: [],
      drawerNoChoiceQuestionList: [],
      examQuestionSize: 0,
      currentSelectedQuestion: {
        question_id: "",
        question_index: "",
        question_question: "",
        question_optionA: "",
        question_optionB: "",
        question_optionC: "",
        question_optionD: "",
        question_optionE: "",
        choice: "",
        inputAnswer:"",
        question_type:"",
        answer_img_url:""
      },
      showBigPicDialog: false,
      currentSelectedPicPath: "",
      app_id: "",
      deviceStatus: 0,
      DEBUG_DEV: false,
      drawerAnswerInfo: "",
      progess_num:0,
      progess_num_total:0,
      project_code: "",
      student_score:0,
      ip: "",
      messageList: [],
      messageType: "Message Types",
      messageStr: "",
      timer: "",
      showRedTip: false,
      screenChangeCount: 0,
      heartBeatTimer: undefined,
      currentLang: "英文",
      WORDS: {
        中文: {
          exchange_lang: "English",
          page_pre: "上一页",
          page_next: "下一页",
          question_pre: "上一题",
          question_next: "下一题",
          menu: "目录",
          question: "咨询",
          submit_exam: "提交试卷",
          count_down: "倒计时",
          exchange_screen_counts: "切屏次数",
          btn_cancel: "取消",
          btn_confirm: "确定",
          want_to_submit_answer: "是否提交试卷",
          all_questions: "所有",
          not_answer: "未答",
          answered: "已答",
          gotoPage: "跳转到",
        },
        英文: {
          exchange_lang: "中文",
          page_pre: "Previous",
          page_next: "Next",
          question_pre: "Previous Question",
          question_next: "Next Question",
          menu: "Menu",
          question: "Messages",
          submit_exam: "Submit Answers",
          count_down: "Count Down",
          exchange_screen_counts: "Screen Switches",
          btn_cancel: "Cancel",
          btn_confirm: "Confirm",
          want_to_submit_answer: "Are you sure you want to submit your exam? Once you click 'Confirm', your answers will be final, and you will not be able to re-enter the exam.",
          all_questions: "All Questions",
          not_answer: "Not Answers",
          answered: "Answered",
          gotoPage: "Go To",
        },
      },
      showAnswerResultClick: false,
      forceShutDown: false,
      isConnect: false,
      showloadingView: false,
      countDownInternal: undefined,
      hourLab: "00",
      minuteLab: "00",
      secondLab: "00",
      invigilateStatus: false,
      group_id: "",
      browerStr:'',
      havePicUrl:true,
      parentShowBigPic:0,
      parentReloadPic:0,
      submitAnswrQuestionId:'',
      submitAnswrQuestionChoice:'',
      submitAnswrQuestionImg_url:'',
      initial_score:0,


      
    };
  },
  created() {
    window.addEventListener("offline", this.networkErrorCallback);
    window.addEventListener("online", this.networkSuccessCallback);
  },
  async mounted() {


    // const script = document.createElement('script');
    // script.src = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js?config=Tex-AMS-MML_HTMLorMML';
    // script.onload = () => {
    //   // 脚本加载完毕后的操作
    // };
    // document.head.appendChild(script);
  
    this.project_code = getProjectCode();
    this.app_id = getAppId();
    let groupIdRes = await getGroupId(this.app_id);
    this.group_id = groupIdRes.data;
    this.template_id = this.$route.query.template_id;
    this.invigilateStatus = this.$route.query.invigilateStatus;

    this.user_id = getUserId();

    this.browerStr = this.browerType()

    // MathJax.typeset([this.$el], {
    //   showProcessingMessages: false // 是否显示MathJax处理消息
    // });
    //先签到
    let result = await this.studentSignIn();
    if (result) {
      if (this.invigilateStatus == "true") {
        this.signStatus = false;
      } else {
        this.signStatus = true;
      }
    } else {
      return;
    }

    //打开socket
    this.isConnect = true;
    if (this.DEBUG_DEV) {
      this.fetchData(1);
    }
    getUserIP((ip) => {
      console.log(2222, ip);
      this.ip = ip;
    });

    if (this.invigilateStatus == "true") {
      this.signStatus = false;
      this.fetchData(1);
    } else {
      this.refreshVisible();
    }

    this.getMyMessages();

    // this.timer = setInterval(this.showRedMessage, 5000);
    // if (window.history && window.history.pushState) {
    //   history.pushState(null, null, document.URL);
    //   window.addEventListener("popstate", this.onBrowserBack, false);
    // }
    this.checkStudentExam();

    setTimeout(() => {
      this.pushHistory();
      window.addEventListener("popstate", this.goBack, false);
      window.history.pushState("forward", null, ""); //在IE中必须得有这两行
      window.history.forward(1);
    }, 1000);
  },
  methods: {
    async beforeAttachUpload(file) {
      console.log(123, file);
      let isJPG = false;
      if (file.type === "image/jpeg") {
        isJPG = true;
      }
      if (file.type === "image/png") {
        isJPG = true;
      }
      let isLt2M = file.size / 1024 / 1024 < 5;
      console.log(123, isJPG, isLt2M);
      if (!isJPG) {
        this.$message.error("The file can be JPG or PNG!");
        return;
      }
      if (!isLt2M) {
        this.$message.error("The file exceed 5MB!");
        return;
      }
      const result = await this.getQiniuToken();

      console.log('4444');
      if (result.data.code === 0) {
        const token = result.data.data;
        this.observable = qiniu.upload(
          file,
          new Date().getTime() + file.name,
          token
        );
        const observer = {
          complete: (res) => {
            let url = "http://mainbucket.reachable-edu.com/" + res.key;
            this.currentSelectedQuestion.answer_img_url = url;
            console.log(2222, url);
            this.saveUrlAnswer(url);
          },
        };
        this.observable.subscribe(observer);
      } else {
      }
    },
    async getQiniuToken() {
      return new Promise((resolve) => {
        getQiniuToken().then((res) => {
          resolve(res);
        });
      });
    },
    ssssave: function (val) {
                // 获取预览文本
                console.log(this.testAss)
                console.log(val)
            },
    //刷新页面
    loadExam(){

      this.gotoImageIndex = 1;

      this.fetchData(2)
    },
    enlargeClick(){
      this.parentShowBigPic++
    },
    browerType() {
        var ua = navigator.userAgent.toLocaleLowerCase();
        // 判断是否为IE(第一个是正常的IE，第二个是Edge，第三个是IE11)
        var isIE = (ua.indexOf("compatible") > -1 && ua.indexOf("msie") > -1) || (ua.indexOf("edge") > -1) || (ua.indexOf(
            'trident') > -1 && ua.indexOf("rv:11.0") > -1);
        // 判断是否为IE5678，!+[1,] 在IE5678返回true，在IE9、IE10、IE11返回false
        var isLteIE8 = isIE && !+[1,];
        // 用于防止因通过IE8+的文档兼容性模式设置文档模式，导致版本判断失效
        var dm = document.documentMode,
            isIE5,
            isIE6,
            isIE7,
            isIE8,
            isIE9,
            isIE10,
            isIE11;
        if (dm) {
            isIE5 = dm === 5;
            isIE6 = dm === 6;
            isIE7 = dm === 7;
            isIE8 = dm === 8;
            isIE9 = dm === 9;
            isIE10 = dm === 10;
            isIE11 = dm === 11;
        } else {
            // 判断是否为IE5，IE5的文本模式为怪异模式(quirks),真实的IE5.5浏览器中没有document.compatMode属性  
            isIE5 = (isLteIE8 && (!document.compatMode || document.compatMode === 'BackCompat'));
            // 判断是否为IE6，IE7开始有XMLHttpRequest对象  
            isIE6 = isLteIE8 && !isIE5 && !XMLHttpRequest;
            // 判断是否为IE7，IE8开始有document.documentMode属性  
            isIE7 = isLteIE8 && !isIE6 && !document.documentMode;
            // 判断是否IE8  
            isIE8 = isLteIE8 && document.documentMode;
            // 判断IE9，IE9严格模式中函数内部this不为undefined  
            isIE9 = !isLteIE8 && (function () {
                "use strict";
                return !!this;
            }());
            // 判断IE10，IE10开始支持严格模式，严格模式中函数内部this为undefined   
            isIE10 = isIE && !!document.attachEvent && (function () {
                "use strict";
                return !this;
            }());
            // 判断IE11，IE11开始移除了attachEvent属性  
            isIE11 = isIE && !document.attachEvent;
        };
        // 因为字符串存在覆盖重复原因，判断顺序不可随意修改
        if (isIE5) return 'IE5';
        if (isIE6) return 'IE6';
        if (isIE7) return 'IE7';
        if (isIE8) return 'IE8';
        if (isIE9) return 'IE9';
        if (isIE10) return 'IE10';
        if (isIE11) return 'IE11';
        if (ua.indexOf('green') > -1) return 'green';
        if (ua.indexOf('qq') > -1) return 'qq';
        if (ua.indexOf('bidu') > -1) return 'baidu';
        if (ua.indexOf('lb') > -1) return 'lb';
        if (ua.indexOf('world') > -1) return 'world';
        if (ua.indexOf('2345') > -1) return '2345';
        if (ua.indexOf('maxthon') > -1) return 'aoyou';
        if (ua.indexOf('tao') > -1) return 'taobao';
        if (ua.indexOf('ubrowser') > -1) return 'UC';
        if (ua.indexOf('coolnovo') > -1) return 'coolnovo';
        if (ua.indexOf('opr') > -1) return 'opera';
        if (ua.indexOf('se') > -1) return 'sougou';
        if (ua.indexOf('firefox') > -1) return 'firefox';
        if (ua.indexOf('safari') > -1 && ua.indexOf("version") > -1) return ('safari');
        if (window.navigator.mimeTypes[40] || !window.navigator.mimeTypes.length) return '360';
        if (ua.indexOf("chrome") > -1 && window.chrome) return ('chrome');
        return 'Other';
    },
    clickRemoveQuestionUrlAnswer(){
      let that = this
      this.$alert('Are you sure remove this picture ?','Tips',{
        confirmButtonText:'Confirm',
        callback:action => {
          if(action == 'confirm'){
            that.saveUrlAnswer('')
          }
        }
      })
    },
    saveUrlAnswer(url) {
      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }

      let item = this.currentSelectedQuestion;

      let choice = this.currentSelectedQuestion.choice;

      //团队做题 同步做题记录

      let question_id = item.question_id;
      let index = this.currentSelectedQuestionIndex;
      this.questionAnswers[question_id] = { choice: choice, status: "no" };
      this.drawerQuestionList[index]["choice"] = choice;

      console.log(2241112);

      submitAnswer(
        question_id,
        this.template_id,
        choice,
        this.user_id,
        this.ip,
        this.test_event,
        getProjectCode(),
        url
      ).then((res) => {
        if (res.data.code == 0) {
          this.questionList[index]["answer_img_url"] = url;
          this.currentSelectedQuestion.answer_img_url = url;
          // this.questionAnswers[question_id]['status'] ='yes'
          // item.choice = choice
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
            showClose: true,
          });
        }
      });
    },

    pdfCallback(numbers) {
      this.examImageSize = numbers;
    },
    reLoadImage(url) {
      var time = new Date().getTime();
      let new_url = url + "?" + time;
      this.exam_images[this.currentSelectedImageIndex] = new_url;
    },
    pushHistory() {
      history.pushState(null, null, document.URL);
    },
    networkSuccessCallback() {
      this.$message.success("Connected!");
    },
    networkErrorCallback() {
      this.$message.error("Connection Error, please check your network!");
    },
    handleGotoImagChanged(value) {
      //图片不需要跟着一起切换
      // this.currentSelectedImageIndex = this.gotoImageIndex-1
    },
    beforeShowSelectImageDialog() {
      this.gotoImageIndex = this.currentSelectedImageIndex + 1;
      this.showGotoPageDialog = true;
    },
    gotoSelectedImage() {
      if (this.$refs.pdfView.checkIsLoadingPdf()) {
        return;
      }
      this.resetViewPosition();
      var index = this.gotoImageIndex;
      this.$refs.pdfView.gotoTargetPage(index);
      this.currentSelectedImageIndex = index;
      this.showGotoPageDialog = false;
    },
    doExchangeLang() {
      if (this.currentLang === "中文") {
        this.currentLang = "英文";
      } else {
        this.currentLang = "中文";
      }
    },
    getShowWord(value) {
      return this.WORDS[this.currentLang][value];
    },

    studentSignIn() {
      return new Promise((resolve, reject) => {
        studentSignWithTemplate(
          this.user_id,
          this.template_id,
          this.project_code
        ).then((res) => {
          var result = res.data.data;
          var begin_time = result.begin_time;
          var end_time = result.end_time;
          var test_duration = result.test_duration;

          //补考人员 获取补考时间
          if (result.repair_sign) {
            begin_time = result.repair_sign.repair_begin_time;
            end_time = result.repair_sign.repair_end_time;
            test_duration = result.repair_sign.repair_duration;
          }
          
          if (result.do_exercises_index != undefined){
            this.currentSelectedQuestionIndex = parseInt(result.do_exercises_index)
            
          }

          console.log("begin_time :" + begin_time);
          console.log("end_time :" + end_time);
          console.log("test_duration :" + test_duration);

          var currentTime = new Date().getTime() / 1000;

          // end_time = currentTime+ 1000;

          console.log("currentTime :" + currentTime);
          // if(currentTime<=begin_time){
          //   this.$router.go(-1)
          //   this.$message.error('考试时间还没有到！')
          //   return
          // }
          if (currentTime > end_time) {
            this.$message.error("You've already completed the exam.");
            this.$router.go(-1);
            reject(false);
          }

          this.showloadingView = true;
          // console.log('1231312312' + result.remain_wait_time)

          // const loading = this.$loading({
          //   lock: true,
          //   text: `需要等待时间${parseInt(result.remain_wait_time / 60)}分钟`,
          //   spinner: "el-icon-loading",
          //   background: "rgba(0, 0, 0, 0.7)",
          // });

          // var diff =
          var timeCha = result.remain_wait_time;
          // timeCha = 0
          this.countDownInternal = setInterval(() => {
            this.showStartBtn = false;
            timeCha--;
            if (timeCha < 0) {
              var currentTime1 = new Date().getTime() / 1000;

              var diff = end_time - currentTime1;
              var last_time = parseInt(diff);
              this.startCountDown(last_time);

              clearInterval(this.countDownInternal);
              this.showloadingView = false;
              resolve(true);
            } else {
              this.formatShowTime(timeCha);
            }
          }, 1000);

          // setTimeout(() => {
          //   var diff = end_time - currentTime;
          //   var last_time = parseInt(diff);
          //   this.startCountDown(last_time);
          //   //  this.showloadingView = false
          //   // loading.close();
          //   resolve(true);
          // }, result.remain_wait_time * 1000);
        });
      });
    },

    formatShowTime(time) {
      // console.log('倒计时' + time)
      var theTime = time;
      var theTime1 = 0; // 分
      var theTime2 = 0; // 小时
      if (theTime > 60) {
        //超过1分钟
        theTime1 = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60); //剩余秒数
        if (theTime1 > 60) {
          //超过60分钟
          theTime2 = parseInt(theTime1 / 60); //多少小时
          theTime1 = parseInt(theTime1 % 60);
        }
      }
      this.hourLab = this.formatTime1(theTime2);
      this.minuteLab = this.formatTime1(theTime1);
      this.secondLab = this.formatTime1(theTime);
    },
    formatTime1(time) {
      if (time >= 10) {
        return time;
      }
      return "0" + time;
    },

    checkStudentExam() {
      checkIsUploadTest(this.template_id, getUserId()).then((res) => {
        var result = res.data;

        if (result) {
          this.canExam = false;
          console.log("---sorry--");
          this.clearCountDown();
        }
      });
    },
    doSubmitAnswer() {

      if (this.remainStamp <= 1800 && this.test_type != 'mock_test'){

        this.$message({
          message: 'Submission allowed after 30 minutes into the exam',
          type: "warning",
        });
        // this.$message.error('Submission is only allowed within the last 15 minutes.');
        return
      }

      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }
      this.showSubmitAnswerDialog = true;
    },
    handleClose() {
      this.messageDrawer = false;
    },
    showRedMessage() {
      getMyMessages(getUserId(), getProjectCode()).then((res) => {
        // console.log(1111,res)

        this.traversalData(res.data.data);
      });
      console.log(11);
    },
    traversalData(newData) {
      var haveNoReply = false;
      var newTip = false;

      for (var i = 0; i < newData.length; i++) {
        var message = newData[i];
        if (message.message_reply_id == "") {
          haveNoReply = true;
        }

        if (i < this.messageList.length) {
          var oldmessage = this.messageList[i];

          if (message.message_reply_id == oldmessage.message_reply_id) {
            console.log("是一致的");
          } else {
            console.log("是不一致的");
            newTip = true;
          }
        }
      }

      console.log("是否需要刷新", haveNoReply);
      console.log("是否需要提示新消息", newTip);

      if (haveNoReply == false) {
        clearInterval(this.timer);
      }

      if (newTip == true) {
        this.showRedTip = true;
      } else {
        this.showRedTip = false;
      }

      this.messageList = newData;
    },
    showMessage() {
      getMyMessages(getUserId(), getProjectCode()).then((res) => {
        // console.log(1111,res)
        this.messageList = res.data.data;
      });
      this.messageStr = "";
      this.messageDrawer = true;
      this.showRedTip = false;
      // clearInterval(this.timer);
    },
    getMyMessages() {
      getMyMessages(getUserId(), getProjectCode()).then((res) => {
        // console.log(1111,res)
        this.messageList = res.data.data;
      });
    },
    sendMessage() {
      if (this.messageType == "Message Types") {
        this.$message.error("Message Types");
        return;
      }
      if (this.messageStr == "") {
        this.$message.error("Please in your questions");
        return;
      }

      sendMessage(
        getUserId(),
        this.messageType,
        "student",
        this.messageStr,
        getProjectCode(),
        this.test_event
      ).then((res) => {
        this.messageStr = "";
        this.messageType = "Message Types";

        this.$message({
          message: "Send message successfully",
          type: "success",
        });

        this.getMyMessages();
        // this.timer = setInterval(this.showRedMessage, 5000);
      });
    },
    showBigPic(path) {
      this.currentSelectedPicPath = "https://" + path;
      this.showBigPicDialog = true;
    },
    doSelectQuestion(index) {
      this.currentSelectedQuestionIndex = index;
      this.currentSelectedQuestion = this.questionList[index];
      this.refreshMath()
      this.showDrawer = false;
      this.resetViewPosition();
    },
    showExchangeTip() {
      this.$alert(
        "Screen switch monitoring is in place and will be reviewed by the invigilator. Any suspected cheating will be assessed manually based on your screen-sharing logs. If non-intentional switches occur, like pop-ups or accidental off-screen clicks, simply continue with the exam as usual.",
        "Screen Switches",
        {
          confirmButtonText: "OK",
          callback: (action) => {},
        }
      );
    },
    showBrowerTip() {
      this.$alert(
        "To ensure a smooth exam experience, please use the latest version of the Chrome browser. Other browsers may lead to unforeseen errors during the testing process.",
        "Browser",
        {
          confirmButtonText: "OK",
          callback: (action) => {},
        }
      );
    },
    //判断已经数量

    loadAnswerNum(){
      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }
      
      var temp = this.drawerQuestionList;
      var total = temp.length;
      var answered = 0;
      var answeredTrue = this.initial_score;

      for (var i = 0; i < temp.length; i++) {
        var info = temp[i];
        if (info.choice !== "") {
          answered++;


          var infoMarks = parseFloat(info.question_marks)

          if (info.question_answer === info.choice){
            answeredTrue = answeredTrue + infoMarks;
            // console.log(1111,answeredTrue , info.question_marks ,'dui')

          }else{
            answeredTrue = answeredTrue - infoMarks;
            // console.log(1111,answeredTrue , info.question_marks ,'cuo')

          }

        }

      }
      this.progess_num_total = total
      this.progess_num = answered
      this.student_score = answeredTrue
    },

    showMenu() {
      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }
      var choiceList = [];
      var noChoiceList = [];
      var temp = this.drawerQuestionList;
      var total = temp.length;
      var answered = 0;
      for (var i = 0; i < temp.length; i++) {
        var info = temp[i];
        var tempInfo = {
          question_question: info["question_question"],
          parentIndex: i,
        };
        if (info.choice !== "") {
          answered++;
          choiceList.push(tempInfo);
        } else {
          noChoiceList.push(tempInfo);
        }
      }
      this.drawerAnswerInfo = "Answered：" + answered + "/" + total;
      // drawerAnswerInfo
      this.drawerChoiceQuestionList = choiceList;
      this.drawerNoChoiceQuestionList = noChoiceList;
      this.showDrawer = true;
    },
    showPreQuestion() {
      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }
      // this.resetViewPosition()
      var index = this.currentSelectedQuestionIndex;
      index--;
      if (index < 0) {
        this.$message({
          message: "This is the first question",
          type: "warning",
        });
        return;
      }
      this.currentSelectedQuestionIndex = index;
      this.currentSelectedQuestion = this.questionList[index];
      this.refreshMath()
    },

    //下一题

    showNextQuestion() {
      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }
      // this.resetViewPosition()
      var index = this.currentSelectedQuestionIndex;

      var temp = this.drawerQuestionList;

      var currentQu = temp[index]

      if (currentQu.choice !== ""){

      }else{

        this.$message({
          message: "Please select",
          type: "warning",
        });

        return

      }


      submitAnswer(
        this.submitAnswrQuestionId,
        this.template_id,
        this.submitAnswrQuestionChoice,
        this.user_id,
        this.ip,
        this.test_event,
        getProjectCode(),
        this.submitAnswrQuestionImg_url
      ).then((res) => {
        if (res.data.code == 0) {
          this.questionAnswers[this.submitAnswrQuestionId]["status"] = "yes";
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
            showClose: true,
          });
        }
      });


      this.loadAnswerNum()


      index++;
      if (index >= this.examQuestionSize) {
        this.$message({
          message: "It's already the last question",
          type: "warning",
        });
        return;
      }
      this.currentSelectedQuestionIndex = index;
      this.currentSelectedQuestion = this.questionList[index];
      this.refreshMath()

      console.log(11111,this.currentSelectedQuestion)
      //保存 做题的index

      this.submitAnswrQuestionId = this.currentSelectedQuestion.question_id
      this.submitAnswrQuestionChoice = this.currentSelectedQuestion.choice


      studentUpdateTemplateWithIndex(
          this.user_id,
          this.template_id,
          this.project_code,
          index
        ).then((res) => {

        });


    },
    showReload(){
      this.parentReloadPic++
    },
    showPreImage() {
      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }
      if (this.$refs.pdfView.checkIsLoadingPdf()) {
        return;
      }
      this.resetViewPosition();
      this.currentSelectedImageIndex = this.$refs.pdfView.prePage();
    },
    showNextImage() {
      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }
      if (this.$refs.pdfView.checkIsLoadingPdf()) {
        return;
      }
      this.resetViewPosition();
      this.currentSelectedImageIndex = this.$refs.pdfView.nextPage();
    },
    goBack() {
      if (this.forceShutDown) {
        this.$router.go(-1);
        return;
      }

      if (this.showAnswerResultClick) {
        this.$router.go(-1);
        return;
      }

      if (this.deviceStatus != 0 || !this.canExam) {
        this.$router.go(-1);
        return;
      }

      this.$confirm(
        "You are in the middle of a test. Leaving this page will automatically complete your exam. Are you sure you want to exit?",
        "Warning",
        {
          confirmButtonText: "Confirm to Exit",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.quizTrue();
        })
        .catch(() => {});
      // this.$alert('当前正在进行考试，退出后，考试试卷会自动提交，您确定要退出考试？', '提示', {
      //   confirmButtonText: '确定',
      //   callback: () => {
      //     // this.$router.go(-1)
      //     this.quizTrue()
      //   }
      // });
    },
    quizTrue() {
      this.$confirm("You are in the middle of a test. Leaving this page will automatically complete your exam. Are you sure you want to exit?", "Warning", {
        confirmButtonText: "Confirm to Exit",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.uploadAnswers();
          this.$router.go(-1);
        })
        .catch(() => {});

      // this.$alert('确定要退出考试？', '提示', {
      //   confirmButtonText: '确定',
      //   callback: () => {
      //     this.$router.go(-1)
      //   }
      // });
    },
    fetchData(type) {
      this.questionAnswers = {};
      var student_id = getUserId();
      getExerciseTemplateByStudent(this.template_id, student_id).then((res) => {
        this.test_subject = res.data.data.test_subject;
        this.test_type = res.data.data.test_type;

        this.initial_score = res.data.data.initial_score;
        this.questionList = [];
        // this.exam_images = res.data.data.pic_arr;
        // this.examImageSize = this.exam_images.length;
        var index = 0;
        this.sections = res.data.data.sections_item;
        var sections = res.data.data.sections_item;
        for (var i = 0; i < sections.length; i++) {
          var section = sections[i];
          var questions_items = section["questions_item"];
          for (var j = 0; j < questions_items.length; j++) {
            index++;
            var pos = index;
            if (pos < 10) {
              pos = "0" + pos;
            }
            var question = {
              question_id: questions_items[j]["_id"]["$id"],
              question_index: pos,
              question_question: questions_items[j]["question_question"],
              question_question_string: questions_items[j]["question_question_string"],
              question_optionA: questions_items[j]["question_optionA"],
              question_optionA_string: questions_items[j]["question_optionA_string"],
              question_optionB: questions_items[j]["question_optionB"],
              question_optionB_string: questions_items[j]["question_optionB_string"],

              question_optionC: questions_items[j]["question_optionC"],
              question_optionC_string: questions_items[j]["question_optionC_string"],

              question_optionD: questions_items[j]["question_optionD"],
              question_optionD_string: questions_items[j]["question_optionD_string"],

              question_optionE: questions_items[j]["question_optionE"],
              question_optionE_string: questions_items[j]["question_optionE_string"],

              choice: questions_items[j]["user_answer"],
              question_answer: questions_items[j]["question_answer"],
              question_type: questions_items[j]["question_type"],
              answer_img_url:questions_items[j]["user_answer_pic_url"],
              question_marks:  parseFloat(questions_items[j]["question_marks"])

            };
            this.questionList.push(question);
          }
        }
        this.drawerQuestionList = this.questionList;
        this.examQuestionSize = this.questionList.length;

        if (this.currentSelectedQuestionIndex == 0){
          this.currentSelectedQuestionIndex = 0;
          this.currentSelectedQuestion = this.questionList[0];
        }else{
          this.currentSelectedQuestion = this.questionList[this.currentSelectedQuestionIndex];
        }
        this.refreshMath()

        this.submitAnswrQuestionId = this.currentSelectedQuestion.question_id
        this.submitAnswrQuestionChoice = this.currentSelectedQuestion.choice

        

        
        this.scheduleHeartBeat();


        //执行答题进度
        //取第一个字段为PDF文件地址
        var urls = res.data.data.pic_arr;
        // var urls =[]
        // let url = 'https://mainbucket.learningfirst.cn/FBLA%E7%BB%8F%E6%B5%8E%E5%AD%A6%E7%BA%BF%E4%B8%8A%E8%80%83%E8%AF%95%E8%AE%BE%E5%A4%87%E8%A6%81%E6%B1%82.pdf'
        // urls.push(url)
        // urls.push(url)
        // urls.push(url)
        // urls.push(url)
        var newUrls = [];
        for (var j = 0; j < urls.length; j++) {
          var url = urls[j];
          if (url.indexOf("https://") != -1 || url.indexOf("http://") != -1) {
            newUrls.push(url);
          } else {
            newUrls.push("https://" + url);
          }
        }

        if (urls.length <= 0){
          this.havePicUrl = false
        }else{
          this.havePicUrl = true
        }

        if (type != 2){
          this.asyncIntPDF(newUrls)
          this.loadAnswerNum();
        }

        // this.asyncIntPDF(newUrls);
      });
    },
    async asyncIntPDF(urls) {
      this.$refs["pdfView"] && (await this.$refs["pdfView"].initPdfView(urls));
    },
    startCountDown(seconds) {
      if (this.countDownThread) {
        return;
      }

      let allTime = 7200
      this.remainStamp = allTime - seconds;
    
      let totalTime = seconds;
      this.countDownThread = window.setInterval(() => {
        this.countDownTimes = this.formatSeconds(totalTime);

        this.remainStamp ++;
        totalTime--;
        if (totalTime < 0) {
          window.clearInterval(this.countDownThread);
          this.$alert("The exam has ended. You exam paper has been submitted automatically.", "Exam Time Up", {
            confirmButtonText: "Confirm",
            callback: () => {
              this.uploadAnswers();
            },
          });
        }
      }, 1000);
    },
    saveAnswer(choice, item) {
      if (!this.checkDeviceCanWork()) {
        return;
      }
      if (!this.checkCanExam()) {
        return;
      }
      this.submitAnswrQuestionId = item.question_id;

      this.submitAnswrQuestionChoice = choice

      let question_id = item.question_id;
      let index = this.currentSelectedQuestionIndex;
      this.questionAnswers[question_id] = { choice: choice, status: "no" };
      this.drawerQuestionList[index]["choice"] = choice;

      let img_url = '';

      if (item.question_type == 'input'){

        if (item.answer_img_url != ''){
          img_url = item.answer_img_url;
        }
      }

      this.submitAnswrQuestionImg_url = img_url

      //放到 提交里面去
      // submitAnswer(
      //   question_id,
      //   this.template_id,
      //   choice,
      //   this.user_id,
      //   this.ip,
      //   this.test_event,
      //   getProjectCode(),
      //   img_url
      // ).then((res) => {
      //   if (res.data.code == 0) {
      //     this.questionAnswers[question_id]["status"] = "yes";
      //   } else {
      //     this.$message({
      //       message: res.data.msg,
      //       type: "error",
      //       duration: 5000,
      //       showClose: true,
      //     });
      //   }
      // });
    },
    uploadAnswers() {
      let temp_no_answers = {};
      var questionIds = Object.keys(this.questionAnswers);
      questionIds.forEach((key) => {
        var question = this.questionAnswers[key];
        if (question["status"] == "no") {
          temp_no_answers[key] = question["choice"];
        }
      });
      // let answerList = this.questionList.map(item => item.choice);
      var project_code = getProjectCode();
      uploadAnswersV3(
        project_code,
        this.user_id,
        this.template_id,
        this.app_id,
        this.ip,
        this.test_event,
        "personal",
        temp_no_answers
      ).then((res) => {
        if (this.currentAudio) {
          this.currentAudio.pause();
        }
        if (res.data.code === 0) {
          this.$message({
            message: "Your test has been successfully transmitted. For mock tests, it was for preparation purposes only and no grades will be assigned. For formal exams, results will be available within 10-15 business days after your program's exams conclude. Should you need support or have questions, feel free to contact our team. Best of luck with your studies!",
            type: "success",
            duration: 5000,
            showClose: true,
          });
          this.forceShutDown = true;
          this.$router.go(-1);
          //不显示成绩 直接返回
          // this.showAwardDialog(res.data.score);
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
            duration: 5000,
            showClose: true,
          });
          this.forceShutDown = true;
          this.$router.go(-1);
        }
      });
    },
    showAwardDialog(data) {
      this.answerResultDta = data;
      this.showAnswerResultDialog = true;
    },
    formatSeconds(endTime) {
      let secondTime = parseInt(endTime);
      let min = 0;
      let h = 0;
      let result = "";
      if (secondTime > 60) {
        min = parseInt(secondTime / 60);
        secondTime = parseInt(secondTime % 60);
        if (min > 60) {
          h = parseInt(min / 60);
          min = parseInt(min % 60);
        }
      }
      result = `${h.toString().padStart(2, "0")}:${min
        .toString()
        .padStart(2, "0")}:${secondTime.toString().padStart(2, "0")}`;
      return result;
    },
    closeView() {
      this.showAnswerResultClick = true;
      this.$router.go(-1);
    },
    checkCanExam() {
      if (this.DEBUG_DEV) {
        return true;
      }
      if (this.canExam) {
        return true;
      }
      this.$message.error(
        "The system detects your camera and screen sharing is disrupted. You can not continue under this condition."
      );
      return false;
    },
    checkDeviceCanWork() {
      // if(true){
      //   return true
      // }
      if (this.deviceStatus == 0) {
        return true;
      }
      if (this.deviceStatus == 1) {
        this.$message.error(`Your browser does not support screen sharing`);
        return false;
      }
      if (this.deviceStatus == 2) {
        this.$message.error(`Your browser does not support screen sharing`);
        return false;
      }
      if (this.deviceStatus == 3) {
        this.$message.error(`Camera sharing authorization failed`);
        return false;
      }
      if (this.deviceStatus == 4) {
        this.$message.error(`Screen sharing authorization failed`);
        return false;
      }
      return true;
    },
    resetViewPosition() {
      var exam_image = document.getElementById("exam-image");
      var scrollTop = exam_image.scrollTop;
      scroll(scrollTop, 0, exam_image);
    },
    clearCountDown() {
      this.doUploadHeartBeat(false);
      if (this.heartBeatTimer) {
        window.clearInterval(this.heartBeatTimer);
        this.heartBeatTimer = null;
      }
    },
    refreshVisible() {
      getTestVisibleChangeCount(
        this.template_id,
        getUserId(),
        getProjectCode()
      ).then((res) => {
        let count = res.data.data;
        this.screenChangeCount = count;
      });
    },
    scheduleHeartBeat() {
      this.heartBeatTimer = window.setInterval(() => {
        this.doUploadHeartBeat(true);
      }, 30000);
    },
    doUploadHeartBeat(status) {
      uploadTestHeartBeat(getUserId(), this.template_id, status).then(
        (res) => {}
      );
    },
    invigilateCallback() {},
    onCorrectSessionPicSync(e) {
      for (let index in this.sections) {
        if (this.sections[index]._id.$id === e._id.$id) {
          this.sections[index].pic_arr = e.pic_arr;
          if (Number(index) === this.sectionSelect) {
            this.exam_images = e.pic_arr.map((item) => "https://" + item);
          }
        }
      }
    },
    onCorrectQuestionSync(e) {
      let question_id = e._id.$id;
      //循环所有的题目 如果找到该题目id就修改答案并跳出当前循环
      findQuestion: for (let i in this.sections) {
        let section = this.sections[i];
        for (let j in section["questions_item"]) {
          let question = section["questions_item"][j];
          if (question_id === question["_id"]["$id"]) {
            question["question_answer"] = e.question_answer;
            question["question_optionA"] = e.question_optionA;
            question["question_optionA_string"] = e.question_optionA_string;
            question["question_optionB"] = e.question_optionB;
            question["question_optionB_string"] = e.question_optionB_string;
            question["question_optionC"] = e.question_optionC;
            question["question_optionC_string"] = e.question_optionC_string;

            question["question_optionD"] = e.question_optionD;
            question["question_optionD_string"] = e.question_optionD_string;
            question["question_question"] = e.question_question;
            question["question_question_string"] = e.question_question_string;

            let questionIndex = this.questionList.findIndex(
              (x) => x.question_id === question_id
            );
            if (questionIndex !== -1) {
              this.questionList[questionIndex]["question_answer"] =
                e.question_answer;
              this.questionList[questionIndex]["question_optionA"] =
                e.question_optionA;
              this.questionList[questionIndex]["question_optionA_string"] =
                e.question_optionA_string;
              this.questionList[questionIndex]["question_optionB"] =
                e.question_optionB;
              this.questionList[questionIndex]["question_optionB_string"] =
                e.question_optionB_string;
              this.questionList[questionIndex]["question_optionC"] =
                e.question_optionC;
              this.questionList[questionIndex]["question_optionC_string"] =
                e.question_optionC_string;
              this.questionList[questionIndex]["question_optionD"] =
                e.question_optionD;
              this.questionList[questionIndex]["question_optionD_string"] =
                e.question_optionD_string;
              this.questionList[questionIndex]["question_question"] =
                e.question_question;
              this.questionList[questionIndex]["question_question_string"] =
                e.question_question_string;
            }

            break findQuestion;
          }
        }
      }
    },
    refreshMath(){
      console.log(1111,window.MathJax.Hub)
      this.$nextTick(()=>{
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, 'app']);
      })
    }
  },
  
  beforeDestroy() {
    if (this.countDownThread) {
      window.clearInterval(this.countDownThread);
      this.countDownThread = null;
    }
    if (this.countDownInternal) {
      clearInterval(this.countDownInternal);
      this.countDownInternal = null;
    }
    window.removeEventListener("offline", this.networkErrorCallback);
    window.removeEventListener("online", this.networkSuccessCallback);
    window.removeEventListener("popstate", this.goBack, false);
    this.clearCountDown();
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style>
.showAnswerResultDialogStyle {
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  padding: 0;
  box-shadow: 0px 0px 0px 0px transparent;
}

.showMenuDialogStyle {
  background-color: transparent;
  padding: 0;
  box-shadow: 0px 0px 0px 0px transparent;
}

.showSubmitAnswerDialogStyle {
  background-color: transparent;
  padding: 0;
  box-shadow: 0px 0px 0px 0px transparent;
}
</style>

<style lang="scss">
.exam-btn-qa {
  margin-left: 15px;
  cursor: pointer;
  font-size: 18px;
  color: white;
  font-family: "Microsoft YaHei-Bold";
  box-sizing: border-box;
  background-color: #f28801;
  font-weight: bold;
  padding: 6px 12px;
  border-radius: 6px;
}
.exam-btn-exchange-lang {
  margin-left: 15px;
  cursor: pointer;
  font-size: 18px;
  color: #4267c0;
  font-family: "Microsoft YaHei-Bold";
  box-sizing: border-box;
  background-color: #b1ccff;
  font-weight: bold;
  padding: 6px 12px;
  border-radius: 18px;
}

/deep/ .el-tabs__item {
  color: white !important;
}

/deep/ .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  color: white !important;
}

.drawer-question-list {
  width: 100%;
  height: 100%;
  background-color: #2d50b0;
  display: flex;
  flex-direction: column;
}

.drawer-answered {
  margin-left: 15px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  width: 80px;
  color: white;
  background-color: #2d50b0;
  border-radius: 17px;
  font-weight: 600;
  font-size: 16px;
  font-family: Montserrat-SemiBold;
}

.drawer-question-item-root {
  padding: 15px 15px 0 15px;
  cursor: pointer;
  background: #2d50b0;

  .drawer-question-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    color: white;
    font-size: 20px;
    background: #2d50b0;
    padding: 12px;
    box-sizing: border-box;
    font-family: Montserrat-SemiBold;
    border-radius: 16px;
  }

  .selected {
    color: #2d50b0;
    font-family: Montserrat-SemiBold;
    background: #ffffff;
    border-radius: 16px;
  }
}

.drawer-question-item-root:last-child {
  padding-bottom: 30px;
}

.exam-empty-width-view {
  width: 2.184vw;
  height: 100%;
  background-color: #d8eaff;
}

.exam-empty-height-view {
  height: 20px;
  width: 100%;
  background-color: #d8eaff;
}

.exam-question-content {
  padding: 12px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.exam-question-content::-webkit-scrollbar {
  display: none;
}

.exam-image {
  padding: 12px;
  width: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
}
.exam-image1 {
  padding: 0px;
  width: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
}

.exam-image::-webkit-scrollbar {
  display: none;
}

.returnStyle {
  font-family: Montserrat-SemiBold;
  cursor: pointer;
  position: absolute;
  // display: flex;
  // margin-bottom: 10px;
  // align-items: center;
  bottom: 40px;
  margin-left: 45%;
  font-weight: 600;
  width: 140px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  // align-content: center;
  // align-items: center;
  border-radius: 15px;
  background-color: #2d50b0;
  color: white;
}

.infoStyle {
  display: flex;
  border: 1px solid #2d50b0;
  border-radius: 16px;
  color: #2d50b0;
  margin-left: 20px;
  margin-top: -25px;
}

.infoImg {
  width: 14px;
  height: 14px;
}

.box {
  display: flex;
  width: 90%;
  justify-content: left;
  margin-left: 80px;
  margin-right: 80px;
  margin-top: 20px;
  // height: 250px;
  // background-color: #FD5900;
  height: 20vh;
  overflow-x: hidden;
  overflow-y: scroll;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: left;
  align-content: left;
  text-align: left;
  float: left;
}

.child {
  // width: 50px;
  // background-color: aqua;
  height: 30px;
  padding-left: 10px;
}

.awardSubTitle {
  text-align: left;
  color: #2d50b0;
  font-size: 15px;
  margin-top: 20px;
  margin-left: 80px;
}

.awardLine {
  background-color: #00359f;
  margin-left: 80px;
  margin-right: 80px;
  height: 1px;
  margin-top: 10px;
}

.awardTitle1 {
  font-family: Montserrat-SemiBold;
  font-weight: bold;
  text-align: left;
  color: #0036a0;
  font-size: 30px;
  margin-top: 60px;
  margin-left: 80px;
}

.scoreLab1 {
  font-family: Montserrat-SemiBold;
  margin-top: 60px;
  margin-right: 10px;
  height: 40px;
  //   background-color: rebeccapurple;
  text-align: right;
  font-size: 30px;
  color: #fd5900;
  float: right;
}

.scoreLab2 {
  font-family: Montserrat-SemiBold;
  margin-top: 65px;
  margin-right: 80px;
  height: 40px;
  //   background-color: rebeccapurple;
  text-align: right;
  color: #0f429e;
  font-size: 25px;
  float: right;
}

.awardTitle {
  font-family: Montserrat-SemiBold;
  font-weight: bold;
  text-align: center;
  color: #0f429e;
  font-size: 60px;
  margin-top: 30px;
  padding-top: 10px;
}

.answer-result-root {
  margin: 50px auto;
  padding: 0;
  background-image: url("../../assets/awardDetailBg.png");
  width: 80%;
  height: 70vh;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  position: relative;
  border-radius: 20px;
}

.menu-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
}

.submitAnswer-root {
  position: relative;
  margin: 0 auto;
  padding: 0;
  width: 27vw;
  height: 59vh;
  background-image: url("../../assets/bg_submit_answer.png");
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  border-radius: 20px;
}

.option {
  cursor: none;
  text-align: left;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1vw;
  margin-top: 24px;
  border-radius: 10px;
  font-weight: bold;
  color: #072d76;
  font-family: Montserrat-SemiBold;
}

.div_p {
  font-family: Montserrat-SemiBold;
  font-size: 1vw;
  display: inline-block;
  color: #072d76;
}

.questionTitle {
  cursor: none;
  font-family: Montserrat-SemiBold;
  color: #072d76;
  font-size: 1.2vw;
  font-weight: bold;
  text-align: left;
  width: 100%;
}
.exam-goto-page {
  cursor: pointer;
  text-align: center;
  line-height: 50px;
  width: 200px;
  height: 100%;
  background-color: #072d76;
  font-family: "Microsoft YaHei-Bold";
  color: white;
  font-size: 18px;
  font-weight: bold;
}
.exam-menu {
  cursor: pointer;
  text-align: center;
  line-height: 50px;
  width: 140px;
  height: 100%;
  background-color: #072d76;
  font-family: "Microsoft YaHei-Bold";
  color: white;
  font-size: 18px;
  font-weight: bold;
}

.exam-page-bottom {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content:center;
  // justify-content: space-around;
  background: #ffffff;
  // box-shadow: 0px -1px 24px 1px rgba(0, 14, 138, 0.1);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  line-height: 50px;
  background-color: white;
}

.exam-page-bottom-text {
  cursor: pointer;
  font-size: 1.144vw;
  font-family: Microsoft YaHei-Bold;
  font-weight: bold;
  color: #2d50b0;
}

.exam-content-page {
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  height: 100%;
  border-radius: 16px;
  opacity: 1;
}

.exam-submit {
  // margin-right: 30px;
  
  // position: absolute;
  justify-content: flex-end;
  cursor: pointer;
  font-size: 13px;
  height: 40px;
  line-height: 40px;
  color: white;
  font-family: "Microsoft YaHei-Bold";
  box-sizing: border-box;
  background-color: #BE3739;
  margin-bottom: 2px;

  // font-weight: bold;
  // border-radius: 4px;
}
.exam-submit-english {
  top: 80px;
  // margin-right: 30px;
  right: 130px;
  position: absolute;
  justify-content: flex-end;
  cursor: pointer;
  color: white;
  font-family: "Microsoft YaHei-Bold";
  box-sizing: border-box;
  background-color: #072d76;
  padding: 6px 14px;
  // font-weight: bold;
  border-radius: 4px;
}

.exam-count-down {
  color: #11448d;
  font-weight: bolder;
  font-size: 26px;
  padding-left: 5px;
  padding-right: 5px;
  // margin-right: 60px;
  margin-top: 5px;
  width: 70%;
  margin-left: 14%;
  text-align: center;
  color: white;
  background-color: #00359f;
}
.exam-count-down-lable {
  color: #333;
  // font-weight: bolder;
  font-size: 13px;
  height: 20px;
  // margin-right: 60px;
  // top: 60px;
  margin-top: 10px;
  margin-left: 10px;
  // width: 100%;
  // left: 15%;
  text-align: left;
}
.exam-progess-down{
  color: #11448d;
  font-weight: bolder;
  font-size: 25px;
  height:40px;
  padding-left: 5px;
  padding-right: 5px;
  // margin-right: 60px;
  margin-top: 5px;
  width: 100%;
  margin-left: 0;
  text-align: center;
  // background-color: rebeccapurple;
}
.exam-top-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 176px;
}

.exam-content {
  width: 100%;
  background-color: #d8eaff;
  // background-image: url("../../assets/magin_right_bg.png");
  flex: 1;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.exam-top {
  height: 40px;
  // display: flex;
  // background: #e64f29;
  // align-items: center;
  justify-content: flex-end;
  background-repeat: no-repeat;
  background-color: transparent;
  // background-size: cover;
  background: #BE3739;
  // background-image: url("../../assets/exam-top-bg.png");
  position: relative;
}

.exam-root {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  text-align: center;
}

.messageList {
  width: 500px;
  height: 90%;
  left: 5%;
  background-color: white;
  margin-top: 30px;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 10px;
  text-align: left;
}

.inputStyle {
  width: 100%;
  height: 60px;
}

.inputSelect {
  margin-left: 20px;
  width: 150px;
  height: 40px;
}

.inputStyle1 {
  width: 60%;
  height: 40px;
  margin-left: 20px;
  margin-top: 10px;
}

.sendStyle {
  margin-left: 10px;
  background-color: #072d76;
  color: white;
  font-family: Montserrat-SemiBold;
}

.messageList {
  width: 90%;
  height: 90%;
  background-color: white;
  margin-top: 30px;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 10px;
  text-align: left;
}

.list {
  overflow-x: hidden;
  overflow-y: scroll;
}

.messageDetail {
  margin-left: 20px;
  margin-top: 30px;
  width: 90%;
  font-family: Montserrat-SemiBold;
  // height: 50px;
  text-align: left;
}

.studentmessageList {
  width: calc(80% + 30px);
  height: calc(90% - 90px);
  background-color: #072d76;
  margin-top: 30px;
  text-align: left;
}

.redTip {
  position: absolute;
  margin-left: 3vw;
  margin-top: 5px;
  background-color: red;
  width: 12px;
  height: 12px;
  border-radius: 6px;
}
.loadingView {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-left: 0px;
  margin-top: 0px;
  left: 0px;
  top: 0px;
  z-index: 999;
  background-color: #f3f5f9;
}
.loadingImg {
  width: 100%;
  height: 100%;
}
.hourStyle {
  font-size: 50px;
  position: absolute;
  top: 110px;
  left: calc(50% - 250px);
  width: 100px;
  height: 100px;
  line-height: 100px;
  align-content: center;
  align-items: center;
  color: #3e93f3;
  border-radius: 10px;
  border: 2px solid #d8eaff;
}
.minuteStyle {
  font-size: 50px;
  position: absolute;
  top: 110px;
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
  line-height: 100px;
  align-content: center;
  align-items: center;
  color: #3e93f3;
  border-radius: 10px;
  border: 2px solid #d8eaff;
}
.secondStyle {
  font-size: 50px;
  position: absolute;
  top: 110px;
  left: calc(50% + 150px);
  width: 100px;
  height: 100px;
  line-height: 100px;
  align-content: center;
  align-items: center;
  color: #3e93f3;
  border-radius: 10px;
  border: 2px solid #d8eaff;
}
.dianStyle {
  font-size: 50px;
  position: absolute;
  top: 110px;
  left: calc(50% - 150px);
  width: 100px;
  height: 100px;
  line-height: 100px;
  align-content: center;
  align-items: center;
  color: #3e93f3;
}
.dianStyle1 {
  font-size: 50px;
  position: absolute;
  color: #3e93f3;
  top: 110px;
  left: calc(50% + 50px);
  width: 100px;
  height: 100px;
  line-height: 100px;
  align-content: center;
  align-items: center;
}
.loadingInfoStyle {
  width: 100%;
  position: absolute;
  font-size: 15px;
  color: #878fb1;
  top: 250px;
  align-content: center;
  align-items: center;
  text-align: center;
}
.loadingBgView {
  position: absolute;
  background-color: white;
  margin-top: 30px;
  left: 15%;
  width: 70%;
  align-self: center;
  height: 320px;
}
.loadingBgView1 {
  position: absolute;
  background-color: white;
  margin-top: 370px;
  left: 15%;
  width: 70%;
  align-self: center;
  height: 300px;
  
}
.loadingInfoStyle1{
  width: 80%;
  margin-left: 10%;
  margin-top: 30px;
  color: #878fb1;
  font-size: 15px;
  text-align: left;
  word-break: normal;

}
.loadingBgViewInfo {
  margin-top: 20px;
  color: #b2b6c5;
  text-align: center;
  font-size: 20px;
}
.loadingBgViewInfo1 {
  margin-top: 20px;
  color: #878fb1;
  text-align: center;
  font-size: 28px;
}
.projectStyle {
  position: absolute;
  // margin-top: 5px;
  text-align: left;
  width: 90%;
  height: 40px;
  line-height: 40px;
  // background-color: red;
  margin-left: 20px;
  color: white;
  font-size: 20px;
}
.projectStyle1 {
  position: absolute;
  // margin-top: 30px;
  text-align: right;
  width: 70%;
  height: 40px;
  line-height: 40px;
  // background-color: yellow;
  right: 20px;
  color: white;
  font-size: 20px;
}
.invigilateStyle {
  // position: absolute;

  display: flex;
  width: 100%;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0px;
}
.changeCountStyle {
  
  width: 100%;
  text-align: center;
  justify-content: flex-end;
  cursor: pointer;
  color: #BE3739;
  font-family: "Microsoft YaHei-Bold";
  background-color: #EFB93F;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
}

.changeBrowserStyle {

  text-align: center;
  justify-content: flex-end;
  cursor: pointer;
  color: #BE3739;
  font-size: 13px;
  height: 40px;
  line-height: 40px;
  margin-bottom: 2px;
  font-family: "Microsoft YaHei-Bold";
  background-color: #EFB93F;
  
}

.inputAnswerStyle {
  cursor: none;
  text-align: left;
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1vw;
  margin-top: 24px;
  border-radius: 10px;
  font-weight: bold;
  color: #072d76;
  font-family: Montserrat-SemiBold;
}
.attach{
  float: left;
}
.rightMiddleDiv{
  // overflow: auto;
  width: 100%;
  // height: calc(100% - 376px);
  background-color: #f2f2f2;
}
.bottomDiv{
  position:absolute;
  height: 250px;
  bottom: 20px;
  // margin-bottom: 20px;
  width: 100%;
  background-color: #f2f2f2;
}
.exam-message{
  width: 100%; 
  height: 40px;
  font-size: 13px;
  line-height: 40px;
  color: white;
  background-color: #36AB7B;
  margin-bottom: 2px;
  cursor: pointer;

}
.exam-content-nourl-page{
  height: calc(100% - 40px);
  overflow-y: auto;
  word-break: normal;
  text-align: left;
  color: #072d76;
  font-size: 15px;
  padding: 10px;
}
.reloadDiv{
  width: 100px;
  height: 40px;
  font-size: 13px;
  line-height: 40px;
  color: white;
  background-color: #36AB7B;
  margin-left: 5px;
  cursor: pointer;

}
</style>
